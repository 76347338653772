import axios from 'axios';
import dayjs from 'dayjs';
import {apiDateFormats, vars} from 'utils/variables';

const {dateTimeFormat} = apiDateFormats;

const {SUCCESS_API_CODE, DEFAULT_LANGUAGE, OPERATION, PAYMENT, PAYMENT_STATUS} = vars;

const baseUrl = 'pfs3/wapi';

const makeUrl = operation => `${baseUrl}/${operation}`;

export const prepareRequestOptions = ({method = 'POST', operation, data = {}, requestLanguage}) => {
  const language = window.localStorage.getItem('language');

  let payload = Object.assign(data, {
    Language: requestLanguage ? requestLanguage.toUpperCase() :
      language ? language.toUpperCase() :
        DEFAULT_LANGUAGE.toUpperCase()
  });

  return {
    method,
    url: makeUrl(operation),
    data: payload
  }
};

axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data.ResponseCode === '401') {
    localStorage.removeItem('user');
    window.location.replace("/");
  }
  return response;
}, function (error) {
  // Do something with response error
  if (error.status === 401) {
    // DELETE YOUR AUTHENTICATE_USER item from localStorage
    localStorage.removeItem('user');
    window.location.replace("/");
  }
  return Promise.reject(error);
});

export const apiRequest = async (options) => {
  try {
    return await axios(prepareRequestOptions(options));
  } catch (error) {
    throw new Error(`Unable to send request \n ${error}`);
  }
};

export const logOut = async () => {
  localStorage.removeItem('user');
  await apiRequest({
    operation: 'Logout',
  });
  window.location.reload();
};

export const sendRegistrationRequest = async ({
                                                phone, password, referrerCode = null, getParams = null, token
                                              }) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'RegistrationLogin',
      data: {
        Login: phone,
        Password: password,
        ReferrerCode: referrerCode,
        RecaptchaToken: token,
        GetParams: getParams,
      }
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      infoText: request.data.Response && request.data.Response.InfoText,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send RegistrationLogin operation\n ${error}`);
  }
};

export const createBanxeAccount = async (otp) => {
  try {
    const data = {};

    if (otp) {
      data.Params = {
        OtpCode: otp,
      };
    }

    const response = await axios(prepareRequestOptions({
      operation: 'Banxe/CreateAccount',
      data: data,
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send Banxe/CreateAccount operation\n ${error}`);
  }
}

export const merchantCreateOrder = async (params) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'MerchantApiProfile/CreateOrder',
      data: {
        Params: params,
      },
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response && request.data.Response.HostPaymentPageURL,
      error: request.data.ResponseText,
    }
  } catch (e) {
    throw new Error(e);
  }
}

export const merchantGetOrderUrl = async (merchantOrderId) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'MerchantApiProfile/GetOrderUrl',
      data: {
        Params: {
          ID: merchantOrderId,
        },
      },
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response && request.data.Response.HostPaymentPageURL,
      error: request.data.ResponseText,
    }
  } catch (e) {
    throw new Error(e);
  }
}

export const sendRegistrationOtp = async (code) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'RegistrationLogin/ConfirmByCode',
      data: {
        Params: {
          Code: code
        }
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send RegistrationLogin/ConfirmByCode operation\n ${error}`);
  }
};

export const getApplicationSettings = async () => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'Login/SessionConfiguration'
    }));

    window.localStorage.setItem('mid', response.data.Response.mid);

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      userName: response.data.Response.UserName,
      userTypeID: response.data.Response.UserTypeID,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send Login/SessionConfiguration operation\n ${error}`);
  }
};

export const logInRequest = async (login, password, token) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'Login',
      data: {
        Login: login,
        Password: password,
        RecaptchaToken: token,
      }
    }));

    //qwerty123QWERTY

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      authType: request.data.Response && request.data.Response.AuthTypeID,
      infoText: request.data.Response && request.data.Response.InfoText,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send Login operation\n ${error}`);
  }
};

export const logInOtpRequest = async (otp) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'Login/ConfirmByCode',
      data: {
        Params: {
          Code: otp
        }
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send Login operation\n ${error}`);
  }
};

export const getAccountsList2 = async (paymentSystemTypeID) => {
  try {
    const requestedColumns = [
      'ID', 'BankName', 'PaymentSystemTypeID', 'CurrencyViewPrecision', 'GeneralCurrencyCode', 'WallettoParams',
      'GeneralCurrencyViewPrecision', 'InsDate', 'AccountStatusID', 'BankSwiftCode', 'CurrencySymbol', 'Name',
      'AccountNumber', 'CardNumber', 'CurrencyCode', 'AccountTypeID', 'GeneralBalance', 'GeneralCurrencySymbol',
      'Expiry', 'IsLock', 'BankAddress', 'MerchantID', 'CurrencyImageUrl', 'Code', 'CurrencyID', 'CurrencyName',
      'PaymentSystemTypeName', 'CurrencyTypeID', 'MerchantApiProfileID', 'CurrencyPrecision', 'BalanceLastUpdate',
    ];

    const filters = {
      AccountTypeID: 1,
    };

    if (paymentSystemTypeID) {
      requestedColumns.push('Balance');
    }

    const requestOptions = {
      operation: 'Account/List',
      data: {
        Columns: requestedColumns,
        Filters: filters,
        Page: -1,
        Sorts: ['PaymentSystemTypeID', 'InsDate']
      }
    };

    const response = await axios(prepareRequestOptions(requestOptions));

    const result = {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      list: [],
      error: response.data.ResponseText,
      response: response.data,
    }

    if (result.isSuccess) {
      response.data.Response.Account.forEach((account) => {
        if (!paymentSystemTypeID) {
          if (account.PaymentSystemTypeID !== 0) {
            account.IsBalanceError = 1;
          } else {
            account.IsBalanceError = 0;
          }
        } else {
          account.IsBalanceError = account.IsBalanceError ? 1 : 0;
        }

        result.list.push(account);
      });
    }

    return result;
  } catch (error) {
    throw new Error(`Unable to send getAccountsList operation\n ${error}`);
  }
};

export const getWallettoCards = async () => {
  try {
    const requestedColumns = [
      'CardNumber','AccountCurrencyID','AccountID', 'AccountNumber', 'AccountBalance','ID','Expiry','IsLock',
      'PaymentSystemTypeID', 'PaymentSystemTypeName', 'WallettoCardStatusID',
    ];

    const requestOptions = {
      operation: 'WallettoCard/List',
      data: {
        Columns: requestedColumns,
        Page: -1,
      }
    };

    const response = await axios(prepareRequestOptions(requestOptions));

    const result = {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      list: response.data.Response && response.data.Response.WallettoCard,
      error: response.data.ResponseText,
      response: response.data,
    }

    return result;
  } catch (error) {
    throw new Error(`Unable to send getWallettoCards operation\n ${error}`);
  }
};

export const getAccountsList = async (foreignAccountCode) => {
  try {
    const requestOptions = {
      operation: 'Account/List',
      data: {
        Filters: {
          AccountTypeID: 1
        },
        Page: -1,
        Sorts: ['PaymentSystemTypeID', 'InsDate']
      }
    };

    if (foreignAccountCode) {
      requestOptions.data = {
        Filters: {
          Code: foreignAccountCode
        }
      }
    }

    const response = await axios(prepareRequestOptions(requestOptions));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      list: response.data.Response && response.data.Response.Account,
      error: response.data.ResponseText,
      response: response.data,
    }
  } catch (error) {
    throw new Error(`Unable to send getAccountsList operation\n ${error}`);
  }
};

export const checkInternalAccount = async (foreignAccountCode, currencyId) => {
  const params = {
    Code: foreignAccountCode
  };

  if (currencyId) {
    params.CurrencyID = currencyId;
  }

  try {
    const requestOptions = {
      operation: 'ForeignAccount/GetByCode',
      data: {
        Params: params,
      }
    };

    const request = await axios(prepareRequestOptions(requestOptions));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE && request.data.Response,
      account: request.data.Response,
      error: request.data.ResponseText,
    }
  } catch (error) {
    throw new Error(`Unable to send getAccountsList operation\n ${error}`);
  }
};

export const calculateFee = async (data) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'PaymentFee/Calculate',
      data: {
        Params: {
          PaymentSystemTypeID: data.paymentSystemTypeID,
          PaymentTypeID: data.paymentTypeID,
          AccountID: data.accountId,
          Amount: data.amount,
          ToCurrencyAmount: data.toCurrencyAmount,
          ForeignAccountCode: data.foreignAccountCode,
          ForeignCurrencyID: data.currency,
          //PaymentSystemTypeID, PaymentTypeID, AccountID
        }
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send calculateFee operation\n ${error}`);
  }
};

export const makeTransaction = async (params, customParams) => {
  const operation = params.operation;
  delete params.operation;

  const data = {
    Params: params,
  }

  if (customParams) {
    data.Params.CustomParams = customParams;
  }

  try {
    const response = await axios(prepareRequestOptions({
      operation: operation,
      data: data,
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send makeTransaction operation\n ${error}`);
  }
};

export const paymentConfirm = async (data) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'Payment/Confirm',
      data: {
        Params: data,
      }
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send makeTransaction operation\n ${error}`);
  }
};

export const resendPaymentOtp = async (params) => {
  try {
    const request = await apiRequest({
      operation: 'Payment/ResendOTP',
      data: {
        Params: params,
      }
    });

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send resendPaymentOtp operation\n ${error}`);
  }
};

export const paymentReject = async () => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'Payment/Reject',
      data: {
        Params: {} // TODO: operation dont exist
      }
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send paymentReject operation\n ${error}`);
  }
};

export const userChangePassword = async (params) => {
  try {
    const request = await axios(prepareRequestOptions({
      operation: 'UserProfile/ChangePassword2',
      data: {
        Params: params,
      }
    }));

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response,
      error: request.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send userChangePassword operation\n ${error}`);
  }
};

export const userProfileGetList = async () => {
  const obj = 'UserProfile';
  try {
    const response = await axios(prepareRequestOptions({
      operation: `${obj}/List`,
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      responseObj: response.data.Response[obj][0],
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send userProfileGetList operation\n ${error}`);
  }
};

export const userSaveProfileField = async (params) => {
  try {
    const response = await axios(prepareRequestOptions(params));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send userSaveProfileField operation\n ${error}`);
  }
};

export const userProfileConfirmFieldByCode = async (otp, name) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'UserProfile/ConfirmFieldByCode',
      data: {
        Params: {
          Code: otp,
          FieldName: name,
        }
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send userSaveProfileField operation\n ${error}`);
  }
};

export const getCurrencyList = async (isOnlyFiat = false) => {
  const options = {
    operation: 'Currency/List',
  };

  if (isOnlyFiat) {
    Object.assign(options, {
      data: {
        Filters: {
          CurrencyTypeID: OPERATION.FIAT_CURRENCY_ID,
        }
      },
    });
  }

  try {
    Object.assign(options, {
      data: {
        Page: -1,
      },
    });

    const response = await axios(prepareRequestOptions(options));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      list: response.data.Response && response.data.Response.Currency,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send getCurrencyList operation\n ${error}`);
  }
};

export const addNewAccount = async (currencyId, gateTokenId, otpCode) => {
  const params = {
    CurrencyID: currencyId,
    PayGateTokenID: gateTokenId,
  };

  if (otpCode) {
    params.OtpCode = otpCode;
  }

  try {
    const response = await axios(prepareRequestOptions({
      operation: 'Account/Create',
      data: {
        Params: params,
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send getCurrencyList operation\n ${error}`);
  }
};

export const createNewWallettoAccount = async (params) => {

  try {
    const response = await axios(prepareRequestOptions({
      operation: 'Walletto/CreateAccount',
      data: {
        Params: params,
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send getCurrencyList operation\n ${error}`);
  }
};

export const createNewWallettoCard = async (params) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: 'WallettoCard/Create',
      data: {
        Params: params,
      }
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send createNewWallettoCard operation\n ${error}`);
  }
};

export const modPayoutConfirmTypeRequest = async (options) => {
  try {
    const response = await axios(prepareRequestOptions({
      operation: options.operation,
      data: options.data
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response,
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send modPayoutConfirmTypeRequest operation\n ${error}`);
  }
};

export const getAllowedPaymentParams = async (params) => {
  const operationName = 'AllowedPaymentParams';

  try {
    const response = await axios(prepareRequestOptions({
      operation: `Payment/${operationName}`,
      data: {
        Params: params,
      },
    }));

    return {
      isSuccess: response.data.ResponseCode === SUCCESS_API_CODE,
      response: response.data.Response[operationName],
      error: response.data.ResponseText
    }
  } catch (error) {
    throw new Error(`Unable to send AllowedPaymentParams operation\n ${error}`);
  }
}

export const getChartData = async (accountId, date) => {
  const objectName = 'Payment';
  const action = '/List';

  const startDate = dayjs(date).startOf('month').format(dateTimeFormat);
  const endDate = dayjs(date).endOf('month').format(dateTimeFormat);

  try {
    const request = await apiRequest({
      operation: objectName + action,
      data: {
        Filters: {
          AccountID: accountId,
          PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
          //PaymentStatusID: PAYMENT_STATUS.CONFIRMED,
          InsDate: {
            'between': [startDate, endDate],
          },
        },
        Page: -1,
      },
    });

    return {
      isSuccess: request.data.ResponseCode === SUCCESS_API_CODE,
      response: request.data.Response[objectName],
      error: request.data.ResponseText,
    }
  } catch (error) {
    throw new Error(`Unable to send getChartData operation\n ${error}`);
  }
}
