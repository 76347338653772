import React, {useEffect, useRef, useState} from 'react';
import {TextBox} from 'devextreme-react/text-box';
import {SelectBox} from 'devextreme-react/select-box';
import {CustomRule, PatternRule, RequiredRule, Validator} from 'devextreme-react/validator';
import {notifyApp} from 'utils/notifyWrapper';
import {fetchLocalList} from 'utils/functions';
import {useTranslation} from 'react-i18next';
import '../form-input/form-input.scss';

const initialCode = '380';

const itemComponent = (props) => {
  const {data} = props;
  const src = `/flags/${data.alpha_2.toLowerCase()}.svg`;

  return (
    <div className={'phone-input-item'}>
      <img width={20} src={src} alt={data.alpha_2}/>
      {data.display_code}
    </div>
  );
};

const fieldComponent = (props) => {
  const {data} = props;

  if (!data) {
    return <TextBox readOnly={true}/>;
  }

  const src = `/flags/${data.alpha_2.toLowerCase()}.svg`;

  return (
    <div className={'phone-input-item'}>
      <img width={20} src={src} alt={data.alpha_2}/>
      <TextBox
        value={data.display_code}
      />
    </div>
  );
};

const PhoneInput = ({
                      value= '',
                      readOnly = false,
                      onInput = () => {
                      },
                      onValueChanged = () => {
                      },
                      label,
                      validation = {group: 'none', rules: []}
                    }) => {
  const [t] = useTranslation();
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState(initialCode);
  const [phoneNumber, setPhoneNumber] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');
  const phoneInputRef = useRef(null);

  useEffect(() => {
    fetchLocalList('countries').then((fetchedCounrties) => {
      setCountries(fetchedCounrties);
    }).catch((error) => {
      notifyApp(t(error.message));
    });
  }, []);

  const handleValidation = (e) => {
    if (!e.isValid) {
      setErrorMessage(e.brokenRule.message);
    } else {
      setErrorMessage('');
    }
  };

  const handlePhoneChange = (e) => {
    const newPhoneNumber = e.value;
    setPhoneNumber(newPhoneNumber);
    onValueChanged({value: `${countryCode}${newPhoneNumber}`});
  };

  const handleCountryCodeChange = (e) => {
    const newCountryCode = e.value;
    if (newCountryCode) {
      setCountryCode(newCountryCode);
      onValueChanged({value: `${newCountryCode}${phoneNumber}`});
    } else {
      setCountryCode(initialCode);
      onValueChanged({value: `${initialCode}${phoneNumber}`});
    }
  };

  return (
    <div className="form-input">
      <label className="form-input-label">{label}</label>
      <div className="flex gap-2">
        <SelectBox
          readOnly={readOnly}
          className="w-24"
          dataSource={countries}
          displayExpr="display_code"
          valueExpr="phone_code"
          value={countryCode}
          onValueChanged={handleCountryCodeChange}
          height={36}
          searchEnabled
          searchExpr={['display_code', 'phone_code', 'name_en', 'name_uk', 'name_ru']}
          stylingMode="outlined"
          itemComponent={itemComponent}
          fieldComponent={fieldComponent}
        />
        <div className="flex-1">
          <TextBox
            readOnly={readOnly}
            ref={phoneInputRef}
            placeholder={label}
            stylingMode='outlined'
            height={36}
            value={phoneNumber}
            onValueChanged={handlePhoneChange}
            onInput={onInput}
          >
            <Validator onValidated={handleValidation} validationGroup={validation.group}>
              {validation.rules.map((rule, index) => {
                switch (rule.type) {
                  case 'required':
                    return <RequiredRule key={index} message={rule.message}/>;
                  case 'pattern':
                    return <PatternRule key={index} pattern={rule.pattern} message={rule.message}/>;
                  case 'custom':
                    return (
                      <CustomRule
                        key={index}
                        validationCallback={rule.validationCallback}
                        message={rule.message}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Validator>
          </TextBox>
        </div>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default PhoneInput;