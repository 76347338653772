import React, {useState} from 'react';
import {Button} from 'devextreme-react/button';
import PopupSelectBox from '../popup-selectbox';
import {addNewAccount} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import {CheckBox} from 'devextreme-react';
import Validator, {CompareRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {parseGateTokenPair} from 'utils/functions';

const {NOTIFY} = vars;

const SharpayAccountFields = (props) => {
	const {t, i18n} = useTranslation();
	const {currencies, currencyValue, handleClose, successHandler, showOtpPopup, userProfile} = props;
	const [stateCurrencyValue, setStateCurrencyValue] = useState(currencyValue);
	const [confirm, setConfirm] = useState(false);
	const currentLanguage = i18n.language.split('-')[0];

	const resendOtp = async () => {
    const [currencyId, gateTokenId] = parseGateTokenPair(stateCurrencyValue, currencies);

		return await addNewAccount(currencyId, gateTokenId);
	}

	const addSharpayAccount = async (otpCode) => {
		const result = validationEngine.validateGroup('sharpay');
		if (result && result.isValid) {
			let request = {};
      const [currencyId, gateTokenId] = parseGateTokenPair(stateCurrencyValue, currencies);

      request = await addNewAccount(currencyId, gateTokenId, otpCode);

			/*function randomIntFromInterval(min, max) { // min and max included
				return Math.floor(Math.random() * (max - min + 1) + min)
			}

			const chId = randomIntFromInterval(1, 4);

			request.isSuccess = true;
			request.response = {
				ID: randomIntFromInterval(1, 100),
				OtpUrlid: 'httpdsfslf',
				InfoText: `Info text ${chId}`,
				SendChannelID: chId, //	Number	Канал отправки OTP: 1 - Push over FCM, 2 - voicecall, 3 - SMS, 4 - email
			};*/

			if (request.isSuccess) {
				if (otpCode) {
					successHandler();
				} else {
					showOtpPopup({
						onResend: resendOtp,
						onSubmit: async (otpCode) => {
							await addSharpayAccount(otpCode);
						},
						sendChannelId: request.response.SendChannelID,
						infoText: request.response.InfoText,
					});
				}
			} else {
				notifyApp(request.error, NOTIFY.ERROR);
			}
		}
	}

	const confirmCompare = () => {
		return true;
	}

  console.group('currencies');
  console.log(currencies);
  console.groupEnd();

	return <div>
		<PopupSelectBox
			placeholder={t('MAIN.ADD_ACCOUNT.CURRENCY')}
			items={currencies}
			displayExpr={'name'}
			valueExpr={'gateCurrencyPairId'}
			value={stateCurrencyValue}
			changeHandler={({value}) => {
				setStateCurrencyValue(value);
			}}
			enterKeyHandler={() => {
				addSharpayAccount();
			}}
		/>
		<div className={'dx-field add-walletto-checkbox'}>
			<CheckBox
				name={'registration-agreement-checkbox'}
				id={'agreement-checkbox-text'}
				validationMessageMode={'always'}
				defaultValue={false}
				value={confirm}
				text={''}
				onValueChanged={() => {
					setConfirm(!confirm);
				}}
			>
				<Validator validationGroup={'sharpay'}>
					<CompareRule message=""
											 comparisonTarget={confirmCompare}/>
				</Validator>
			</CheckBox>
			<div>
				<span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_PRE')}</span>
				<a
					className={'terms-link'}
					target={'_blank'}
					href={userProfile.TermsUrl}
				>
					{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_LINK')}
				</a>
				<span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_POST')}</span>
			</div>
		</div>
		<div className={'popup-buttons'}>
			<Button
				id={'popup-buttons_add'}
				width={'50%'}
				height={40}
				text={t('MAIN.ADD_ACCOUNT.ADD')}
				type="default"
				stylingMode="contained"
				disabled={!stateCurrencyValue}
				onClick={() => {
					addSharpayAccount();
				}}
			/>
			<Button
				id={'popup-buttons_cancel'}
				width={'50%'}
				height={40}
				text={t('MAIN.ADD_ACCOUNT.CANCEL')}
				type="default"
				stylingMode="outlined"
				onClick={handleClose}
			/>
		</div>
	</div>
}

export default SharpayAccountFields;