import React from 'react';
import snsWebSdk from '@sumsub/websdk';
import {verificationStatus, vars} from 'utils/variables';
import {apiRequest, userProfileGetList} from 'services/async';
import {withTranslation} from 'react-i18next';
import {notifyApp} from 'utils/notifyWrapper';
import {RoundButton} from 'components/buttons/round-button';
import Validator, {CompareRule} from 'devextreme-react/validator';
import {CheckBox} from 'devextreme-react/check-box';
import VerificationProgressBar from './verification-progress-bar/progress-bar';
import {fetchLocalList} from 'utils/functions';
import './verification.scss';

const {SUCCESS_API_CODE} = vars;

const {
  VERIFIED,
  DECLINED,
  NEED_EXTRA_DOCS,
  DECLINED_AFTER_VERIFIED,
  NEED_APPROVAL_FROM_BACKOFFICE,
  IN_PROGRESS,
  NEED_EXTRA_DOCS_AFTER_VERIFIED,
  IN_PROCESS_AFTER_VERIFIED,
} = verificationStatus;

class Verification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsAndConditions: '',
      userProfile: null,
      isCheckedConditions: false,
      sumSub: null,
      isShowProgressBar: true,
      isVerificationStatusFinal: true,
    };
  }

  async componentDidMount() {
    const {isBanxe} = this.props;
    const request = await userProfileGetList();

    if (request.isSuccess) {
      const profile = request.response.UserProfile[0];

      this.setState({
        userProfile: profile,
        isVerificationStatusFinal: !!profile.IsVerificationStatusFinal,
      }, isBanxe ? this.startVerification : null);
    } else {
      notifyApp(request.error);
    }

    fetchLocalList('links').then((links) => {
      this.setState({
        termsAndConditions: links['terms_and_conditions'],
      });
    }).catch(() => {
      notifyApp('Fail to load list')
    });
  }

  logState = () => {
    console.log(this.state);
  };

  checkComparison() {
    return true;
  }

  checkBoxChanged = () => {
    const {isCheckedConditions} = this.state;
    this.setState({
      isCheckedConditions: !isCheckedConditions
    });
  };

  startVerification = async () => {
    const {isBanxe} = this.props;
    const data = {};

    if (isBanxe) {
      data.Params = {
        IsBanxe: 1,
      };
    }

    const request = await apiRequest({
      operation: 'VmVerification/Init',
      data: data,
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      const {ApiUrl, AccessToken, SchemaName, Email, Phone, IsTestEnvironment} = request.data.Response;
      this.setState({
        sumSub: {
          apiUrl: ApiUrl,
          isTestEnvironment: IsTestEnvironment,
          accessToken: AccessToken,
          schemaName: SchemaName,
          email: Email,
          phone: Phone,
        },
        isShowProgressBar: false,
      }, this.launchWebSdk);
    } else {
      notifyApp(request.data.ResponseText);
    }
  };

  launchWebSdk = () => {
    const {accessToken, email, phone, isTestEnvironment} = this.state.sumSub;
    const storageLanguage = window.localStorage.getItem('language');
    let language = storageLanguage.split('-')[0];

    if (!language) {
      language = 'en'
    }

    let snsWebSdkInstance;

    const snsWebSdkProductionInstance = snsWebSdk.init(accessToken, () => {
      // EXPIRATION HANDLER
    })
      .withConf({
        lang: language,
        email: email,
        phone: phone, // if available
        onMessage: (type, payload) => {
          console.log('WebSDK onMessage', type, payload);
        },
        onError: (error) => {
          console.log('WebSDK onError', error);
        },
      }).build();

    const snsWebSdkTestInstance = snsWebSdk.init(accessToken, () => {
      // EXPIRATION HANDLER
    })
      .withConf({
        lang: language,
        email: email,
        phone: phone, // if available
        onMessage: (type, payload) => {
          console.log('WebSDK onMessage', type, payload);
        },
        onError: (error) => {
          console.log('WebSDK onError', error);
        },
      }).build();

    snsWebSdkInstance = isTestEnvironment ? snsWebSdkTestInstance : snsWebSdkProductionInstance;
    snsWebSdkInstance.launch('#sumsub-websdk-container');
  };

  getContentByVerificationStatus = () => {
    const {t} = this.props;
    const {isCheckedConditions, userProfile, termsAndConditions} = this.state;

    if (!userProfile) return null;

    let text;
    let iconName = 'verification-data-blue.svg';
    let isSimple = true;

    switch (userProfile.VmVerificationStatusID) {
      case NEED_EXTRA_DOCS:
        text = 'NEED_EXTRA_DOCS';
        break;
      case NEED_EXTRA_DOCS_AFTER_VERIFIED:
        text = 'NEED_EXTRA_DOCS_AFTER_VERIFIED';
        break;
      case IN_PROCESS_AFTER_VERIFIED:
        text = 'IN_PROCESS_AFTER_VERIFIED';
        break;
      default:
        iconName = 'verification-data.svg';
        text = 'ACCESS';
        isSimple = false;
    }

    return (
      <div className={'verification-init'}>
        <div className={'verification-logo'}>
          <img width={120} src={`./images/icons/${iconName}`} alt=''/>
        </div>
        <div className={'verification-access-text'}>
          {t(`MAIN.START_VERIFICATION.${text}`)}
        </div>
        {!isSimple ? (
          <div className={'verification-checkbox-wrapper'}>
            <div className={'dx-field verification-checkbox'}>
              <CheckBox
                onValueChanged={this.checkBoxChanged}
                defaultValue={false}
                text={''}
              >
                <Validator validationGroup={'registrationGroup'}>
                  <CompareRule message={''}
                               comparisonTarget={this.checkComparison}/>
                </Validator>
              </CheckBox>
              <div className={'registration-agreement-text'}>
                <span>{t('CHECKBOX.PRE_CONFIRMATION_TEXT')}</span>
                <a
                  className={'terms-link'}
                  target={'_blank'}
                  href={termsAndConditions}
                >
                  {t('CHECKBOX.CONFIRMATION_LINK')}
                </a>
                <span>{t('CHECKBOX.POST_CONFIRMATION_TEXT')}</span>
              </div>
            </div>
            <RoundButton
              disabled={!isCheckedConditions}
              width={121}
              text={t('COMMON.START')}
              onClick={this.startVerification}
              customClass={'blue'}
            />
          </div>
        ) : null}
      </div>
    );
  }

  getFinalVerificationComponent = () => {
    const {t} = this.props;
    const {userProfile} = this.state;

    if (!userProfile) return null;

    let text = 'VERIFIED';
    let iconName = 'verification-data-green.svg';

    if (userProfile.VmVerificationStatusID === VERIFIED) {
      text = 'VERIFIED';
      iconName = 'verification-data-green.svg';
    } else if (
      userProfile.VmVerificationStatusID === DECLINED ||
      userProfile.VmVerificationStatusID === DECLINED_AFTER_VERIFIED
    ) {
      text = 'DECLINED';
      iconName = 'verification-data-red.svg';
    }

    return (
      <div className={'verification-init'}>
        <div className={'verification-logo'}>
          <img width={120} src={`./images/icons/${iconName}`} alt=''/>
        </div>
        <div>
          <span className={'verification-access-text'}>{t(`MAIN.START_VERIFICATION.${text}`)}</span>
        </div>
      </div>
    );
  }

  render() {
    const {t} = this.props;
    const {sumSub, userProfile, isVerificationStatusFinal, isShowProgressBar} = this.state;
    const inProgress = userProfile && [NEED_APPROVAL_FROM_BACKOFFICE, IN_PROGRESS].includes(userProfile.VmVerificationStatusID);

    return (
      <div className={'verification-wrapper'}>
        <div className={'verification-head'}>
          <div className={'verification-header_text'}>
            <i className={'mdi mdi-folder-account-outline'}/>
            <span>{t('SETTINGS.LEFT_BLOCK.VERIFICATION.TITLE')}</span>
          </div>
          {isShowProgressBar ? (
            <div className={'verification-progress-bar'}>
              <div className={'verification-progress-row'}>
                <VerificationProgressBar
                  type={'email'}
                  progress={userProfile ? userProfile.IsEmailConfirmed : 0}
                  title={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.EMAIL_VERIFICATION')}
                  description={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.EMAIL_VERIFICATION_DESC')}
                />
                <VerificationProgressBar
                  type={'phone'}
                  progress={userProfile ? userProfile.IsPhoneConfirmed : 0}
                  title={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.PHONE_NUMBER_VERIFICATION')}
                  description={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.PHONE_NUMBER_VERIFICATION_DESC')}
                />
              </div>
              <div className={'verification-progress-row'}>
                <VerificationProgressBar
                  type={'person'}
                  progress={userProfile ? userProfile.VmVerificationStatusID : 0}
                  title={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.IDENTITY_VERIFICATION')}
                  description={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.IDENTITY_VERIFICATION_DESC')}
                />
                <VerificationProgressBar
                  type={'person_living'}
                  progress={0}
                  title={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.VERIFICATION_OF_PLACE_OF_RESIDENCE')}
                  description={t('SETTINGS.RIGHT_BLOCK.VERIFICATION.VERIFICATION_OF_PLACE_OF_RESIDENCE_DESC')}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className={'verification-body'}>
          <div className={'verification-body-wrapper'}>
            {sumSub ? (
              <div className={'verification-iframe'}>
                <div id="sumsub-websdk-container"></div>
              </div>
            ) : isVerificationStatusFinal ? this.getFinalVerificationComponent() : inProgress ? (
              <div className={'verification-in-progress'}>
                <img width={120} src='./images/watch.svg' alt=''/>
                <p>{t('SETTINGS.RIGHT_BLOCK.VERIFICATION.VERIFICATION_IN_PROGRESS')}</p>
                <p>{t('SETTINGS.RIGHT_BLOCK.VERIFICATION.VERIFICATION_IN_PROGRESS_NOTIFY')}</p>
              </div>
            ) : this.getContentByVerificationStatus()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Verification);
/*
// GENERATE ACCESS TOKEN ON THE BACKEND
import snsWebSdk from '@sumsub/websdk'

* **/




